.c-layout {
  height: auto;
  padding: 20px 0;
  display: -ms-grid;
  display: grid;
  grid-template-columns: 30% 70%;
  -ms-grid-columns: 30% 70%;
  gap: 30px;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

@media screen and (max-width: 1200px) {
  .c-layout {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
  }
}
