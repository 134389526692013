.details-wrapper {
  padding: 0 20px;
}

.details-box {
  border-bottom: 1px solid #ffd600;
  padding: 32px 0;
}

.details-box:first-child {
  padding: 0 0 32px;
}

.details-header {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  cursor: pointer;
}

.details-icon-box {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  color: #ffffff;
}

.details-icon-box svg {
  margin-right: 8px;
}

.details-icon-box span {
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 300;
}

.details-percentage-box {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}

.details-percentage {
  font-size: 30px;
  color: #7689a0;
  font-weight: 300;
  margin-right: 20px;
  text-transform: uppercase;
}

.details-percentage.less {
  color: #ffd600;
}

.details-percentage.more {
  color: #7689a0;
}

.details-percentage span {
  margin-left: 8px;
}

.details-percentage-icon {
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
  cursor: pointer;
}

.details-percentage-icon.down {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.details-percentage-icon.up {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.details-content {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: all 0.5s ease-in;
  -moz-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
  position: relative;
  height: 0;
  padding: 0 20px;
}

.details-content.open {
  height: auto;
  max-height: 1000px;
  -webkit-transition: all 0.5s ease-in;
  -moz-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
  padding: 20px;
}

.details-content-head {
  display: -ms-grid;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 12px;
}

.details-info-item {
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 5px;
  font-weight: 500;
}

.details-info-item:first-child {
  color: #7689a0;
  text-align: left;
  font-weight: 275;
}

.details-info-item.upper {
  text-transform: uppercase;
  padding-bottom: 8px;
}
@media screen and (max-width: 1200px) {
  .details-wrapper {
    padding: 0 20px;
  }

  .details-box:first-child {
    padding: 0 0 20px;
  }

  .details-box {
    padding: 20px 0;
  }

  .details-icon-box svg {
    width: 30px;
  }

  .details-icon-box span {
    font-size: 18px;
  }

  .details-percentage {
    font-size: 18px;
  }
}

@media screen and (max-width: 450px) {
  .details-header {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
  }
}