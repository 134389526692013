.spinner-box {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.1);
    border-left: 4px solid #ffffff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 1s linear infinite;
    -moz-animation: spin 1s linear infinite;
    -o-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes spin {
    to {
        -moz-transform: rotate(360deg);
    }
}

@-o-keyframes spin {
    to {
        -o-transform: rotate(360deg);
    }
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
