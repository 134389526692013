.c-button-box {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
}

.c-button {
  background: rgba(255, 214, 0, 1);
  padding: 13px 22px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  color: #22192f;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  border: 1px solid transparent;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.c-button:active {
  border: 1px solid #ffffff;
  background: rgba(255, 214, 0, 0.61);
}

.c-button:hover {
  background: rgba(255, 214, 0, 0.61);
  border: 1px solid #ffffff;
}
