.b-search-box {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 35px;
  max-width: 300px;
}

.b-search-box button {
  margin: 10px 0 25px;
}

.b-search-box span {
  font-size: 14px;
  color: #7689a0;
  font-weight: 200;
  line-height: 21px;
}

.b-search-box span div {
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
}

@media screen and (max-width: 1200px) {
  .b-search-box {
    margin: 0 auto;
    max-width: 500px;
    padding: 0 20px;
  }
}
