.spending-power-title {
  color: #ffd600;
  font-size: 30px;
  text-transform: uppercase;
  line-height: 37px;
  margin-bottom: 8px;
  font-weight: 300;
}

.spending-power-desc {
  color: #ffffff;
  font-size: 22px;
  line-height: 33px;
  font-weight: 300;
  margin-bottom: 22px;
}
.spending-power-cost {
  color: #ffd600;
}
@media screen and (max-width: 1200px) {
  .spending-power-desc {
    font-size: 18px;
  }
}

@media screen and (max-width: 1200px) {
  .spending-power-title {
    font-size: 20px;
  }
}
