.dropdown-container {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
}

.dropdown-label {
  padding: 0 0 8px 4px;
  color: #ffd600;
  font-weight: 700;
  font-size: 18px;
  text-align: left;
  text-transform: uppercase;
}

.dropdown-header {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 297px;
  position: relative;
}

.dropdown-helper.error {
  color: #ff0000;
}

.arrow {
  position: absolute;
  top: 50%;
  right: 22px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  -webkit-transition: 0.3s linear all;
  transition: 0.3s linear all;
}

.arrow.down {
  -webkit-transform: translateY(-50%) rotate(45deg);
  -ms-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
}

.arrow.up {
  -webkit-transform: translateY(-50%) rotate(-135deg);
  -ms-transform: translateY(-50%) rotate(-135deg);
  transform: translateY(-50%) rotate(-135deg);
}

.dropdown-box {
  position: absolute;
  top: 90px;
  left: 0;
  width: 100%;
  background-color: #041222;
  color: #ffffff;
  border-radius: 10px;
  border: 1px solid #ffffff;
  z-index: 2;
  max-height: max-content;
  overflow: hidden;
}

.dropdown-search {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
}

.dropdown-list {
  list-style: none !important;
  padding: 0 !important;
  margin: 0 !important;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 300px;
  min-height: 30px;
}

.dropdown-list li {
  padding: 8px;
  font-weight: 600;
  cursor: pointer;
  text-align: left;
  font-size: 16px;
  text-transform: capitalize;
  border-bottom: 1px solid #ffffff59;
}

.dropdown-list li:hover {
  background-color: #0c2c4e;
}

.dropdown-empty {
  padding: 15px;
  cursor: default;
  text-align: center;
}
@media screen and (max-width: 1200px) {
  .dropdown-header {
    margin: 0 auto;
    width: 100%;
    max-width: 500px;
  }
}