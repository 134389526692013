.c-input-box {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 297px;
}
.c-input-label {
  padding: 0 0 8px 4px;
  color: #ffd600;
  font-weight: 700;
  font-size: 18px;
  text-align: left;
  text-transform: uppercase;
}
.c-input {
  border-radius: 10px !important;
  border: 1px solid #ffffff !important;
  color: #ffffff !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  padding: 13px 10px !important;
  background: #041222 !important;
  &.error {
    border: 1px solid #ff0000;
  }
}
.c-input-helper {
  &.error {
    color: #ff0000;
  }
}
@media screen and (max-width: 1200px) {
  .c-input-box {
    margin: 0 auto;
    width: 100%;
    max-width: 500px;
  }
}
